'use client';

import { ReactNode, createContext, useContext, useState } from 'react';

import Toast from '@/components/ui/Toast';

type ToastType = 'success' | 'error' | 'warning';

interface ToastOptions {
    message: string;
    type?: ToastType;
    duration?: number;
}

interface ToastItem extends ToastOptions {
    id: number;
    type: ToastType;
    duration: number;
}

interface ToastContextProps {
    addToast: (options: ToastOptions) => void;
    removeToast: (id: number) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

let id = 0;

const defaultOptions: ToastOptions = {
    message: '',
    type: 'success',
    duration: 4000,
};

function createToast(options: ToastOptions): ToastItem {
    return {
        ...defaultOptions,
        ...options,
        id: id++,
        type: options.type || 'success',
        duration: options.duration || 4000,
    };
}

interface ToastProviderProps {
    children: ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
    const [toasts, setToasts] = useState<ToastItem[]>([]);

    const addToast = async (options: ToastOptions) => {
        const newToast = createToast(options);
        setToasts((prevToasts) => [...prevToasts, newToast]);

        setTimeout(() => {
            removeToast(newToast.id);
        }, newToast.duration);
    };

    const removeToast = (id: number) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            {toasts.map((toast) => (
                <Toast key={toast.id} message={toast.message} type={toast.type} />
            ))}
        </ToastContext.Provider>
    );
}

export function useToast() {
    const context = useContext(ToastContext);
    if (context === undefined) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
}
