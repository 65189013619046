import { ClassValue, clsx } from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { twMerge } from 'tailwind-merge';

import { Recommendations } from '@/types/aura';

dayjs.extend(relativeTime);

export const formatRelativeTime = (dateString: string): string => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return dayjs(date).fromNow();
};
/**
 * Combines multiple class names using `clsx` and merges them using `tailwind-merge`.
 *
 * This function takes any number of class values, processes them using `clsx` to handle conditional and
 * dynamic class names, and then merges the resulting class names with `tailwind-merge` to ensure that
 * Tailwind CSS classes are merged correctly and conflicting classes are handled properly.
 *
 * @param {...ClassValue[]} inputs - The class values to combine and merge. These can be strings, arrays, or objects.
 * @returns {string} The combined and merged class names.
 *
 * @example
 * // Combining static and dynamic class names
 * cn('bg-red-500', isActive && 'text-white', ['p-4', 'm-2'])
 * // Returns: 'bg-red-500 text-white p-4 m-2' (assuming isActive is true)
 *
 * @example
 * // Merging conflicting Tailwind CSS classes
 * cn('bg-red-500', 'bg-blue-500')
 * // Returns: 'bg-blue-500' (the second class takes precedence)
 */
export function cn(...inputs: ClassValue[]): string {
    return twMerge(clsx(inputs));
}

export const formatDate = (dateString: string, format: string = 'MM/DD/YY'): string => {
    if (!dateString) return dateString;

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString;

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const replacements: Record<string, string> = {
        YYYY: year,
        YY: year.slice(-2),
        MM: month,
        DD: day,
        HH: hours,
        mm: minutes,
        ss: seconds,
    };

    return format.replace(/YYYY|YY|MM|DD|HH|mm|ss/g, (match) => replacements[match] || match);
};
export const sortRecommendations = (data: Recommendations) => {
    return Object.entries(data)
        .map(([groupName, groupData]) => {
            const { group_weight, categories } = groupData;

            return {
                groupName,
                groupWeight: group_weight,
                categories: categories.sort((a, b) => b.category_weight - a.category_weight),
            };
        })
        .sort((a, b) => b.groupWeight - a.groupWeight);
};

export const transformedRecurringAttributes = (recurring_attributes) => {
    return recurring_attributes.map((attribute: any) => ({
        title: attribute.title,
        content: attribute.description,
        color: attribute.color,
        attr: { 'data-count': attribute.count },
    }));
};
